import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IGetProductListPayload } from '../models/ProductList';

const GET_PRODUCT_LIST = gql`
  query getProductList($path: String!, $payload: any) {
    getProductList (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "searchPlaygroundUri") {
        data
        pageInfo
      }
  }
`;

const getProductListServiceV4 = (payload: IGetProductListPayload) => client.query({
  query: GET_PRODUCT_LIST,
  variables: { path: URL.getProductListV4, payload },
});

export default getProductListServiceV4;
