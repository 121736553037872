const urlParam = {
  searchString: 'q',
  pincode: 'pc',
  debugESQuery: 'db_esq',
  rankingStrategy: 'ranking_strategy',
  nDCG: 'n',
  judgeAlias: 'judge',
  ranking: 'ranking',
  debugESqueryV2: 'debug_esq',
  customerIds: 'customer_ids',
  dealId: 'deal_id',
  creationStartDateGe: 'creation_start_date_ge',
  creationEndDateLe: 'creation_end_date_le',
  followUpStartDateGe: 'follow_up_start_date_ge',
  followUpEndDateLe: 'follow_up_end_date_le',
  city: 'city',
  state: 'state',
  enquiryStatus: 'enquiry_status',
  source: 'source',
  userAliasId: 'user_alias_id',
  projectId: 'project_id',
  customerType: 'customer_type',
  // 1. ranking_strategy="v1" will sort without relevance score
  // 2. ranking_strategy="v2" will sort with relevance score
  // 3. ranking_strategy="v3" will search using knn vector search
  // 3. ranking_strategy="" will sort with relevance score (by default will sort with relevance score)
};

export default urlParam;
