import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IGetProductListPayload, IProduct } from '../models/ProductList';

// eslint-disable-next-line import/prefer-default-export
export enum GetProductDetailsActionTypes {
  GET_PRODUCT_DETAILS_V4 = 'GET_PRODUCT_DETAILS_V4',
  GET_PRODUCT_DETAILS_SUCCESS_V4 = 'GET_PRODUCT_DETAILS_SUCCESS_V4',
  GET_PRODUCT_DETAILS_FAILED_V4 = 'GET_PRODUCT_DETAILS_FAILED_V4',
  GET_PRODUCT_DETAILS_RESET_V4 = 'GET_PRODUCT_DETAILS_RESET_V4',
}

interface IGetProductDetailsActionData {
  payload: IGetProductListPayload;
}

interface IGetProductsSuccessActionData {
  productDetails: Array<IProduct>;
  esQuery: any;
  pageInfo: IPageInfo;
}

export const getProductDetailsV4 = createAction(GetProductDetailsActionTypes.GET_PRODUCT_DETAILS_V4)<IGetProductDetailsActionData>();
export const getProductDetailsSuccess = createAction(
  GetProductDetailsActionTypes.GET_PRODUCT_DETAILS_SUCCESS_V4,
)<IGetProductsSuccessActionData>();
export const getProductDetailsFailure = createAction(
  GetProductDetailsActionTypes.GET_PRODUCT_DETAILS_FAILED_V4,
)<IErrorActionData>();
export const getProductDetailsReset = createAction(GetProductDetailsActionTypes.GET_PRODUCT_DETAILS_RESET_V4)();

export type GetProductDetailsAction = ActionType<typeof getProductDetailsV4>;
type GetProductDetailsSuccessAction = ActionType<typeof getProductDetailsSuccess>;
type GetProductDetailsFailureAction = ActionType<typeof getProductDetailsFailure>;
type GetProductDetailsResetAction = ActionType<typeof getProductDetailsReset>;

export type GetProductDetailsActionsV4 = GetProductDetailsAction
| GetProductDetailsSuccessAction
| GetProductDetailsFailureAction
| GetProductDetailsResetAction;
