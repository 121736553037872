const URL = {
  login: '/users/login/v2',
  autoLogin: '/users/login/auto/v2',
  verifyOtp: '/users/otp/verify/v2',
  resendOTP: '/users/otp/resend/v2',
  logout: '/users/logout/v2',
  getCreditApplicationsV3: '/accounts/credit/application/v3',
  getCreditDetails: '/accounts/credit/application/{aliasId}/v3',
  getCreditInfoDetails: '/accounts/credit/info/data/v2',
  getCreditInfoDetailsv3: '/accounts/credit/info/data/v3',
  getCPODetails: '/orders/customer/purchase/{aliasId}/v1',
  getCustomerCreditList: '/accounts/credit/info/customer/v3',
  getSiteDetails: '/customers/sites/{aliasId}/v1',
  getOfficeDetails: '/customers/branches/{aliasId}/v1',
  getCustomerDetails: '/customers/{aliasId}/v3',
  getSupplyOrderDetails: '/orders/customer/supply/{aliasId}/v1',
  getSupplyOrderList: '/orders/customer/supply/v2',
  getCustomerServiceKycData: '/customers/kyc/v1',
  getUserServiceKycData: '/users/kyc/v1',
  getPresignedUrlKyc: '/kycs/url/presigned/v1',
  getPresignedUrlOrder: '/orders/url/presigned/v1',
  getCustomerRepresentativesV3: '/customers/representatives/v3',
  getUserDetails: '/users/{aliasId}/v1',
  getUserDetailsList: '/users/v1',
  getCustomerList: '/customers/v3',
  getInternalUserList: '/users/im_reps/v1',
  getProductList: '/products/v1',
  getProductGroupList: '/products/product_groups/v1',
  getProductCategoryList: '/search/categories/v1',
  getProductServicesList: '/products/services/v3',
  getSuppliersList: '/suppliers/info/v2',
  getSiteListV3: '/customers/sites/v3',
  updateCreditState: '/accounts/credit/application/state/{creditAliasId}/v3',
  createScheduleOrder: '/orders/schedules/v1',
  updateSupplyOrder: '/orders/customer/supply/{aliasId}/v1',
  updateScheduleOrder: '/orders/schedule/{scheduleOrderAliasId}/v1',
  updateScheduleOrderState: '/orders/schedule/state/{scheduleOrderAliasId}/v1',
  getScheduleOrderList: '/orders/customer/scheduled/v2',
  updateCreditInfo: '/accounts/credit/info/{aliasId}/v3',
  getPickupLocationList: '/suppliers/pickup-locations/list-advanced/v2',
  updateSupplyOrderState: '/orders/customer/supply/state/{orderAliasId}/v1',
  getGSTListDetails: '/customers/gst/v3',
  getOfficeList: '/customers/branches/v3',
  getOrderList: '/oms/delphius/orders/supply/v1',
  getOrderDetails: '/oms/delphius/orders/supply/{orderAliasId}/v1',
  getCustomerCreditInfo: '/', // Todo: Get update api for Order Credit endpoint
  getCreditSiteList: '/customers/sites/v3',
  createVehicle: '/vehicles/v1',
  editVehicle: '/vehicles/{aliasId}/v1',
  getGroupList: '/vehicles/groups/v1',
  getMakerList: '/vehicles/makers/v1',
  getModelList: '/vehicles/models/v1',
  getCityList: '/vehicles/address/v1',
  getDriversList: '/users/v2',
  createPeople: '/vehicles/drivers/v1',
  editPeople: '/vehicles/drivers/{aliasId}/v1',
  getVehicleDetails: '/vehicles/{aliasId}/v1',
  getVehiclesList: '/vehicles/v1',
  getInvoiceDetails: '/accounts/credit/invoice/data/v3',
  getApproveAndRejectDetails: '/oms/delphius/orders/supply/{CUSoId}/state/v1',
  getApproveAndRejectDetailsEnterprise: '/oms/phoenix/orders/supply/{CUSoId}/credit/v1',
  getStatesList: '/customers/address/states/v3',
  getProductListV4: '/products/v2',
  getCustomerListV1: '/customers/v1',
  getCustomerListV2: '/customers/v2',
  getProjectListV1: '/projects/v1',
  getDealListV1: '/deals/v1',
  getEnquiryListV1: '/enquiries/v1',
  getProductFilterList: '/product_filters/v2',
  getAutoSuggest: '/suggestions/v2',
};

export default URL;
