/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getProductDetailsSuccess,
  getProductDetailsFailure,
  GetProductDetailsAction,
  GetProductDetailsActionTypes,
} from '../actions/GetProductDetails';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import getProductListServiceV4 from '../services/GetProductList';

const GetProductDetailsEpicV4 = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetProductDetailsActionTypes.GET_PRODUCT_DETAILS_V4)),
  mergeMap((action: GetProductDetailsAction) => from(
    getProductListServiceV4(
      action.payload.payload,
    ),
  ).pipe(
    map((response) => getProductDetailsSuccess({
      productDetails: response.data.getProductList.data.data || [],
      esQuery: response.data.getProductList.data.esQuery,
      pageInfo: response.data.getProductList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getProductDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetProductDetailsEpicV4;
